<!--
* @Description 行业案例-汾湖站交通枢纽配套工程
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="con">
      <h1 class="hyalTitle" v-if="$i18n.locale == 'English'">{{ $t("fhz.section1_title") }}<br/>{{ $t("fhz.section1_title2") }}</h1>
      <h1 class="hyalTitle" v-else>{{ $t("fhz.section1_title") }}</h1>

      <div class="conText mb40">
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content1") }}</p>
      </div>
      <div class="plr60">
        <div class="conImgH600 mb40">
          <img :src="imgs[0].url" alt="" />
        </div>
      </div>
      <div class="conText mb40">
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content2") }}</p>
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content3") }}</p>
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content4") }}</p>
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content5") }}</p>
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content6") }}</p>
      </div>
      <div class="plr60">
        <div class="conImgH600 mb40">
          <img :src="imgs[1].url" alt="" />
        </div>
      </div>
      <div class="conText pb40">
        <p style="text-indent: 34px;">{{ $t("fhz.section1_content8") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {url: require("../../../assets/images/industryImg/位图.png")},
        {url: require("../../../assets/images/industryImg/位图2.png")},
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/industryImg/5fc6-2c9e6c6658af68005233c6c04d31f5701@2x(2).png");

    background-size: 100% 100%;

    background-repeat: no-repeat;
  }

  .con {
    width: 1200px;
    text-align: center;
    // padding: 0 360px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
